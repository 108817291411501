import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveConta({ commit }, data) {
      try {
        return await api.post('/conta', data)
      } catch (error) {
        throw error
      }
    },

    async getContas({ commit }, data) {
      try {
        let url = `/conta`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getBancos({ commit }, data) {
      try {
        let url = `/banco`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getConta({ commit }, contaId) {
      try {
        const response = await api.get(`/conta/${contaId}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateConta({ commit }, data) {
      try {
        return await api.put(`/conta/${data.id}`, data.conta)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
