import auth from './modules/auth/auth'
import layouts from './modules/layout/layouts'
import users from './modules/user/users'
import unidades from './modules/unidade/unidade'
import setores from './modules/setor/setor'
import perfis from './modules/perfis/perfis'
import cargos from './modules/cargo/cargos'
import modules from './modules/module/modules'
import commons from './modules/common/commons'
import clients from './modules/client/clients'
import projects from './modules/client/projects'
import contratos from './modules/client/contratos'
import modeloPeca from './modules/projeto/modelos/peca/modeloPeca'
import modeloProjeto from './modules/projeto/modelos/projeto/modeloProjeto'
import projetos from './modules/projeto/projetos'
import fornecedor from './modules/fornecedor/fornecedor'
import contas from './modules/financeiro/contas'
import categoriasContas from './modules/financeiro/categoriasContas'
import subcategoriasContas from './modules/financeiro/subcategoriasContas'
import formaPagamento from './modules/financeiro/formaPagamento'
import centroCusto from './modules/financeiro/centroCusto'
import transferencia from './modules/financeiro/transferencia'
import lancamento from './modules/financeiro/lancamento'
import perguntasPesquisas from './modules/pesquisaDeSatisfacao/perguntasPesquisas'
import tiposPesquisas from './modules/pesquisaDeSatisfacao/tiposPesquisas'
import respostasPesquisas from './modules/pesquisaDeSatisfacao/respostasPesquisas'

export default {
  auth,
  layouts,
  users,
  unidades,
  setores,
  cargos,
  perfis,
  modules,
  commons,
  clients,
  projects,
  contratos,
  modeloPeca,
  modeloProjeto,
  projetos,
  fornecedor,
  contas,
  categoriasContas,
  subcategoriasContas,
  formaPagamento,
  centroCusto,
  transferencia,
  lancamento,
  perguntasPesquisas,
  tiposPesquisas,
  respostasPesquisas,
}
