import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Auth from '../views/auth/Auth.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    name: 'PasswordRecovery',
    path: PATHS.PASSWORD_RECOVERY,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecovery" */ '../views/auth/PasswordRecovery.vue'
      ),
  },
  {
    name: 'NewPassword',
    path: PATHS.NEW_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "newPassword" */ '../views/auth/NewPassword.vue'
      ),
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'ResponderPesquisa',
    path: PATHS.RESPONDER_PESQUISA,
    component: () =>
      import(
        /* webpackChunkName: "pesquisaDeSatisfacao" */ '../views/pesquisaDeSatisfacao/respostasPesquisa/RespostaPesquisa.vue'
      ),
  },
  {
    name: 'Administration',
    path: PATHS.ADM,
    component: () =>
      import(
        /* webpackChunkName: "administration" */ '../views/administration/Administration.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '0',
    },
    children: [
      {
        name: 'AdministrationUsers',
        path: PATHS.ADM_USERS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/users/Users.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01.001',
        },
      },
      {
        name: 'AdministrationCargos',
        path: PATHS.ADM_CARGOS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/cargos/Cargos.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01.001',
        },
      },
      {
        name: 'AdministrationUnidades',
        path: PATHS.ADM_UNIDADES,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/unidades/Unidades.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01.001',
        },
      },
      {
        name: 'AdministrationSetores',
        path: PATHS.ADM_SETORES,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/setores/Setores.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01.001',
        },
      },
      {
        name: 'AdministrationPerfis',
        path: PATHS.ADM_PERFIS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/perfil/Perfil.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01.001',
        },
      },
    ],
  },
  {
    name: 'Financeiro',
    path: PATHS.FINANCEIRO,
    component: () =>
      import(
        /* webpackChunkName: "financeiro" */ '../views/financeiro/Financeiro.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '0',
    },
    children: [
      {
        name: 'FinanceiroContas',
        path: PATHS.CONTAS,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/contas/Contas.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.04.001',
        },
      },
      {
        name: 'CategoriasContas',
        path: PATHS.CATEGORIASCONTAS,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/categoriasContas/CategoriasContas.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.02.001',
        },
      },
      {
        name: 'SubcategoriasContas',
        path: PATHS.SUBCATEGORIASCONTAS,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/categoriasContas/SubcategoriasContas.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.03.001',
        },
      },
      {
        name: 'FormaPagamento',
        path: PATHS.FORMA_PAGAMENTO,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/formaPagamento/FormaPagamento.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05.001',
        },
      },
      {
        name: 'CentroCusto',
        path: PATHS.CENTRO_CUSTO,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/centroCusto/CentroCusto.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.06.001',
        },
      },
      {
        name: 'Transferencia',
        path: PATHS.TRANSFERENCIA,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/transferencia/Transferencia.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.07.001',
        },
      },
      {
        name: 'Lancamentos',
        path: PATHS.LANCAMENTOS,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/lancamentos/Lancamentos.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.01.001',
        },
      },
      {
        name: 'PreLancamento',
        path: PATHS.PRE_LANCAMENTO,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/preLancamento/PreLancamento.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.01.001',
        },
      },
      {
        name: 'CreateLancamento',
        path: PATHS.CREATE_LANCAMENTO,
        component: () =>
          import(
            /* webpackChunkName: "financeiro" */ '../views/financeiro/lancamentos/CreateEditLancamento.vue'
          ),
        props: true,
        meta: {
          requiresAuth: true,
          permission: '2.01.002',
        },
      },
    ],
  },
  {
    name: 'Projetos',
    path: PATHS.PROJETOS,
    component: () =>
      import(/* webpackChunkName: "projeto" */ '../views/projeto/Projeto.vue'),
    meta: {
      requiresAuth: true,
      permission: '1',
    },
    children: [
      {
        name: 'ProjetosModeloPecaCategoria',
        path: PATHS.PROJETO_MODELO_PECA_CATEGORIA,
        component: () =>
          import(
            /* webpackChunkName: "projeto" */ '../views/projeto/modelos/peca/Categoria.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
      {
        name: 'ProjetosModeloPeca',
        path: PATHS.PROJETO_MODELO_PECA,
        component: () =>
          import(
            /* webpackChunkName: "projeto" */ '../views/projeto/modelos/peca/ModeloPeca.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
      {
        name: 'ProjetosModeloProjeto',
        path: PATHS.PROJETO_MODELO_PROJETO,
        component: () =>
          import(
            /* webpackChunkName: "projeto" */ '../views/projeto/modelos/projeto/ModeloProjeto.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
    ],
  },
  {
    name: 'BackOffice',
    path: PATHS.BACKOFFICE,
    component: () =>
      import(
        /* webpackChunkName: "backOffice" */ '../views/backoffice/BackOffice.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '1',
    },
    children: [
      {
        name: 'BackOfficeClients',
        path: PATHS.BACKOFFICE_CLIENTS,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/clients/Clients.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
      {
        name: 'BackOfficeCreateClients',
        path: PATHS.BACKOFFICE_CREATE_CLIENTS,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/clients/CreateClient.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
      {
        name: 'BackOfficeDetailsClient',
        path: PATHS.BACKOFFICE_DETAILS_CLIENT,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/clients/ClientDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
    ],
  },

  // Pesquisa de Satisfação
  {
    name: 'PesquisaDeSatisfacao',
    path: PATHS.PESQUISA_SATISFACAO,
    component: () =>
      import(
        /* webpackChunkName: "pesquisaDeSatisfacao" */ '../views/pesquisaDeSatisfacao/PesquisaDeSatisfacao.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '4',
    },
    children: [
      {
        name: 'PerguntasPesquisa',
        path: PATHS.PERGUNTA_PESQUISA,
        component: () =>
          import(
            /* webpackChunkName: "pesquisaDeSatisfacao" */ '../views/pesquisaDeSatisfacao/perguntas/Perguntas.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '4.01.001',
        },
      },
      {
        name: 'TiposPesquisas',
        path: PATHS.TIPO_PESQUISA,
        component: () =>
          import(
            /* webpackChunkName: "pesquisaDeSatisfacao" */ '../views/pesquisaDeSatisfacao/tiposDePesquisa/TiposDePesquisa.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '4.02.001',
        },
      },
      {
        name: 'FormularioTiposPesquisas',
        path: PATHS.FORMULARIO_PESQUISA_SATISFACAO,
        component: () =>
          import(
            /* webpackChunkName: "pesquisaDeSatisfacao" */ '../views/pesquisaDeSatisfacao/tiposDePesquisa/FormularioDePesquisa.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '4.02.001',
        },
      },
      {
        name: 'FinalDaPesquisa',
        path: PATHS.FINAL_PESQUISA_SATISFACAO,
        component: () =>
          import(
            /* webpackChunkName: "pesquisaDeSatisfacao" */ '../views/pesquisaDeSatisfacao/respostasPesquisa/FinalDaPesquisa.vue'
          ),
      },
      {
        name: 'ResultadosDaPesquisa',
        path: PATHS.RESULTADOS_PESQUISA_SATISFACAO,
        component: () =>
          import(
            /* webpackChunkName: "pesquisaDeSatisfacao" */ '../views/pesquisaDeSatisfacao/resultados/ResultadosPesquisa.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '4.02.001',
        },
      },
    ],
  },

  {
    name: 'Fornecedor',
    path: PATHS.FORNECEDOR,
    component: () =>
      import(
        /* webpackChunkName: "fornecedor" */ '../views/fornecedor/Fornecedores.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '1',
    },
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn && to.name === 'Auth') {
    next({ name: 'Home' })
  } else if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedIn && !localStorage.getItem('token')) {
      next({ name: 'Auth' })
    } else {
      if (to.meta.permission !== undefined) {
        if (!store.getters.getModuleUserByCode(to.meta.permission)) {
          next({ name: 'Unauthorized' })
        }
      }
      next()
    }
  } else {
    next()
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedIn && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})

export default router
