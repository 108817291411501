import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveCentroCusto({ commit }, data) {
      try {
        return await api.post('/centro-custo', data)
      } catch (error) {
        throw error
      }
    },

    async getCentrosCustos({ commit }, data) {
      try {
        let url = `/centro-custo`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCentroCusto({ commit }, centroCustoId) {
      try {
        const response = await api.get(`/centro-custo/${centroCustoId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getVerbasByCentroCusto({ commit }, centroCustoId) {
      try {
        const response = await api.get(`/centro-custo/${centroCustoId}/verbas`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateCentroCusto({ commit }, data) {
      try {
        return await api.put(`/centro-custo/${data.id}`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
