import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    // salva uma respostas de pesquisa de satisfação
    async saveRespostaPesquisa({ commit }, dados) {
      try {
        return await api.post('/responder-pesquisa', dados)
      } catch (error) {
        throw error
      }
    },

    // busca todas as respostas individuais da pesquisa completo (perguntas e respostas individuais separadas)
    async getRespostasIndividuaisPesquisa({ commit }, dados) {
      try {
        let url = `/resposta-pesquisa-individual/${dados.tipoPesquisaId}/${dados.dataInicio}/${dados.dataFinal}`
        if (dados && dados.unidades) {
          url += `?unidades=${dados.unidades}`
        }
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },

    // busca todas as perguntas do tipo de pesquia respondido naquele periodo
    async getPerguntasPesquisa({ commit }, dados) {
      try {
        const url = `/perguntas-pesquisa/${dados.tipoPesquisaId}/${dados.dataInicio}/${dados.dataFinal}`
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },

    // busca as respostas de um id
    async getRespostasPesquisaById({ commit }, respostaPesquisaId) {
      try {
        const url = `/resposta-by-id-resposta-pesquisa/${respostaPesquisaId}`
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },

    // busca todas as respostas de um tipo de pesquisa por periodo
    async getRespostasTipoPesquisa({ commit }, dados) {
      try {
        let url = `/respostas-by-tipo-pesquisa/${dados.tipoPesquisaId}/${dados.dataInicio}/${dados.dataFinal}`
        if (dados && dados.unidades) {
          url += `?unidades=${dados.unidades}`
        }
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },

    // busca todas as respostas por alternativas
    async getRespostasAlternativasPesquisa({ commit }, dados) {
      try {
        let url = `/resposta-pesquisa/${dados.tipoPesquisaId}/${dados.dataInicio}/${dados.dataFinal}`
        if (dados && dados.unidades) {
          url += `?unidades=${dados.unidades}`
        }
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },

    // busca todas as respostas por paragrafo
    async getRespostasParagrafoPesquisa({ commit }, dados) {
      try {
        let url = `/resposta-paragrafo-pesquisa/${dados.tipoPesquisaId}/${dados.dataInicio}/${dados.dataFinal}`
        if (dados && dados.unidades) {
          url += `?unidades=${dados.unidades}`
        }
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },

    // busca todas as respostas NPS
    async getRespostasNpsPesquisa({ commit }, dados) {
      try {
        let url = `/resposta-nps-pesquisa/${dados.tipoPesquisaId}/${dados.dataInicio}/${dados.dataFinal}`
        if (dados && dados.unidades) {
          url += `?unidades=${dados.unidades}`
        }
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },

    // busca todas as respostas CSAT
    async getRespostasCsatPesquisa({ commit }, dados) {
      try {
        let url = `/resposta-csat-pesquisa/${dados.tipoPesquisaId}/${dados.dataInicio}/${dados.dataFinal}`
        if (dados && dados.unidades) {
          url += `?unidades=${dados.unidades}`
        }
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },

    // Calcula a satisfação geral
    async getRSatisfacaoGeralPesquisa({ commit }, dados) {
      try {
        let url = `/satisfacao-geral-pesquisa/${dados.tipoPesquisaId}/${dados.dataInicio}/${dados.dataFinal}`
        if (dados && dados.unidades) {
          url += `?unidades=${dados.unidades}`
        }
        return await api.get(url)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
