const BASE_URL = '/'
const AUTH = BASE_URL
const PASSWORD_RECOVERY = `${BASE_URL}password-recovery`
const NEW_PASSWORD = `${BASE_URL}new-password/:key`
const HOME = `${BASE_URL}home`
const ADM = `${BASE_URL}administration`
const ADM_USERS = `${ADM}/users`
const ADM_CARGOS = `${ADM}/cargos`
const ADM_UNIDADES = `${ADM}/unidades`
const ADM_SETORES = `${ADM}/setores`
const ADM_PERFIS = `${ADM}/perfis`
const PROJETOS = `${BASE_URL}projetos`
const FORNECEDOR = `${BASE_URL}fornecedor`
const PROJETO_MODELO_PECA_CATEGORIA = `${PROJETOS}/modelos-peca-categoria`
const PROJETO_MODELO_PECA = `${PROJETOS}/modelos-peca`
const PROJETO_MODELO_PROJETO = `${PROJETOS}/modelos-projeto`
const BACKOFFICE = `${BASE_URL}backoffice`
const BACKOFFICE_CLIENTS = `${BACKOFFICE}/clients`
const BACKOFFICE_CREATE_CLIENTS = `${BACKOFFICE}/create-client`
const BACKOFFICE_DETAILS_CLIENT = `${BACKOFFICE_CLIENTS}/:clientId`
const UNAUTHORIZED = `${BASE_URL}not-authorized`
const FINANCEIRO = `${BASE_URL}financeiro`
const CONTAS = `${FINANCEIRO}/contas`
const CATEGORIASCONTAS = `${FINANCEIRO}/categorias-contas`
const SUBCATEGORIASCONTAS = `${FINANCEIRO}/subcategorias-contas`
const FORMA_PAGAMENTO = `${FINANCEIRO}/forma-pagamento`
const CENTRO_CUSTO = `${FINANCEIRO}/centro-custo`
const TRANSFERENCIA = `${FINANCEIRO}/transferencia`
const LANCAMENTOS = `${FINANCEIRO}/lancamentos`
const PRE_LANCAMENTO = `${FINANCEIRO}/pre-lancamento`
const CREATE_LANCAMENTO = `${FINANCEIRO}/create-lancamento/:lancamentoId?`
const PESQUISA_SATISFACAO = `${BASE_URL}pesquisa-satisfacao`
const PERGUNTA_PESQUISA = `${PESQUISA_SATISFACAO}/pergunta-pesquisa`
const TIPO_PESQUISA = `${PESQUISA_SATISFACAO}/tipo-pesquisa`
const RESPONDER_PESQUISA = `${PESQUISA_SATISFACAO}/responder-pesquisa/:tipoPesquisaId`
const FORMULARIO_PESQUISA_SATISFACAO = `${TIPO_PESQUISA}/formulario/:tipoPesquisaId`
const FINAL_PESQUISA_SATISFACAO = `${PESQUISA_SATISFACAO}/final-pesquisa`
const RESULTADOS_PESQUISA_SATISFACAO = `${PESQUISA_SATISFACAO}/resultados-pesquisa`

export default {
  BASE_URL,
  AUTH,
  PASSWORD_RECOVERY,
  NEW_PASSWORD,
  HOME,
  ADM,
  ADM_USERS,
  ADM_CARGOS,
  ADM_PERFIS,
  ADM_UNIDADES,
  ADM_SETORES,
  PROJETOS,
  PROJETO_MODELO_PECA_CATEGORIA,
  PROJETO_MODELO_PECA,
  PROJETO_MODELO_PROJETO,
  BACKOFFICE,
  BACKOFFICE_CLIENTS,
  BACKOFFICE_CREATE_CLIENTS,
  BACKOFFICE_DETAILS_CLIENT,
  UNAUTHORIZED,
  FORNECEDOR,
  FINANCEIRO,
  CONTAS,
  CATEGORIASCONTAS,
  SUBCATEGORIASCONTAS,
  FORMA_PAGAMENTO,
  CENTRO_CUSTO,
  TRANSFERENCIA,
  LANCAMENTOS,
  CREATE_LANCAMENTO,
  PRE_LANCAMENTO,
  PESQUISA_SATISFACAO,
  PERGUNTA_PESQUISA,
  TIPO_PESQUISA,
  FORMULARIO_PESQUISA_SATISFACAO,
  RESPONDER_PESQUISA,
  FINAL_PESQUISA_SATISFACAO,
  RESULTADOS_PESQUISA_SATISFACAO,
}
