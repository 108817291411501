import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveTransferencia({ commit }, data) {
      try {
        return await api.post('/transferencia', data)
      } catch (error) {
        throw error
      }
    },

    async getTransferencias({ commit }, data) {
      try {
        let url = `/transferencia`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
