import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async savePerfil({ commit }, perfil) {
      try {
        return await api.post('/perfil', perfil)
      } catch (error) {
        throw error
      }
    },

    async getPerfis({ commit }) {
      try {
        const response = await api.get('/perfil')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPerfil({ commit }, perfilId) {
      try {
        const response = await api.get(`/perfil/${perfilId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updatePerfil({ commit }, perfil) {
      try {
        return await api.put(`/perfil/${perfil.id}`, perfil)
      } catch (error) {
        throw error
      }
    },

    async getPerfilModules({ commit }, perfilId) {
      try {
        const response = await api.get(`perfil/${perfilId}/modules`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updatePerfilModules({ commit }, data) {
      try {
        return await api.put(`perfil/${data.perfilId}/modules`, data.modules)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
