import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async savePerguntaPesquisa({ commit }, dados) {
      try {
        return await api.post('/pergunta-pesquisa', dados)
      } catch (error) {
        throw error
      }
    },

    async getPerguntasPesquisas({ commit }) {
      try {
        const response = await api.get('/pergunta-pesquisa')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSetor({ commit }, perguntaId) {
      try {
        const response = await api.get(`/pergunta-pesquisa/${perguntaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPerguntasByTipoPesquisa({ commit }, tipoPesquisaId) {
      try {
        const response = await api.get(
          `/tipo-pesquisa/${tipoPesquisaId}/perguntas`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPerguntasParaResponder({ commit }, tipoPesquisaId) {
      try {
        const response = await api.get(
          `/responder-pesquisa/${tipoPesquisaId}/perguntas`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updatePerguntaPesquisa({ commit }, dados) {
      try {
        return await api.put(`/pergunta-pesquisa/${dados.id}`, dados)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
