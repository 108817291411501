import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveTipoPesquisa({ commit }, dados) {
      try {
        return await api.post('/tipo-pesquisa', dados)
      } catch (error) {
        throw error
      }
    },

    async createEditFormularioPesquisa({ commit }, dados) {
      try {
        return await api.post(`/tipo-pesquisa/${dados.id}/formulario`, dados)
      } catch (error) {
        throw error
      }
    },

    async getTiposPesquisas({ commit }) {
      try {
        const response = await api.get('/tipo-pesquisa')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTipoPesquisa({ commit }, tipoPesquisaId) {
      try {
        const response = await api.get(`/tipo-pesquisa/${tipoPesquisaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getTipoPesquisaFormularioDeResposta({ commit }, tipoPesquisaId) {
      try {
        const response = await api.get(
          `/tipo-pesquisa-formulario/${tipoPesquisaId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateTipoPesquisa({ commit }, dados) {
      try {
        return await api.put(`/tipo-pesquisa/${dados.id}`, dados.tipo_pesquisa)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
