import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveContrato({ commit }, data) {
      try {
        return await api.post(`clients/${data.client_id}/contratos`, data)
      } catch (error) {
        throw error
      }
    },

    async getContratos({ commit }, data) {
      try {
        let url = `/clients/${data.clientId}/contratos`

        if (data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getContrato({ commit }, data) {
      try {
        const response = await api.get(
          `/clients/${data.clientId}/contratos/${data.contratoId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getContratoPreLancamento({ commit }, contratoId) {
      try {
        const response = await api.get(`/pre-lancamento/${contratoId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getContratosAll({ commit }, data) {
      try {
        let url = `/contratos`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getParcelasByContrato({ commit }, data) {
      try {
        const response = await api.get(
          `/clients/${data.clientId}/contratos/${data.contratoId}/parcelas`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getParcelasPreLancamento({ commit }, contratoId) {
      try {
        const response = await api.get(`/pre-lancamento/${contratoId}/parcelas`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getContasAdicionaisByContrato({ commit }, data) {
      try {
        const response = await api.get(
          `/clients/${data.clientId}/contratos/${data.contratoId}/contas-adicionais`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateContrato({ commit }, data) {
      try {
        return await api.put(
          `/clients/${data.client_id}/contratos/${data.contrato_id}`,
          data,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
