import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveFornecedor({ commit }, data) {
      try {
        return await api.post('/fornecedor', data)
      } catch (error) {
        throw error
      }
    },

    async getFornecedores({ commit }, data) {
      try {
        let url = `/fornecedor`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getFornecedor({ commit }, fornecedorId) {
      try {
        const response = await api.get(`/fornecedor/${fornecedorId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getContatosByFornecedor({ commit }, fornecedorId) {
      try {
        const response = await api.get(`/fornecedor/${fornecedorId}/contatos`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateFornecedor({ commit }, data) {
      try {
        return await api.put(`/fornecedor/${data.id}`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
