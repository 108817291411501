import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveCategoriaConta({ commit }, data) {
      try {
        return await api.post('/categoria-contas', data)
      } catch (error) {
        throw error
      }
    },

    async getCategoriasContas({ commit }, data) {
      try {
        let url = `/categoria-contas`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCategoriaConta({ commit }, categoriaContaId) {
      try {
        const response = await api.get(`/categoria-contas/${categoriaContaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateCategoriaConta({ commit }, data) {
      try {
        return await api.put(
          `/categoria-contas/${data.categoriaContaId}`,
          data.categoriaConta,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
