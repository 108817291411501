import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    // CRIAR MODELO DE PROJETO
    async saveModeloProjeto({ commit }, data) {
      try {
        return await api.post('/modeloProjeto', data)
      } catch (error) {
        throw error
      }
    },

    // BUSCAR TODOS OS MODELOS DE PROJETOS
    async getModelosProjetos({ commit }) {
      try {
        const response = await api.get('/modeloProjeto')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR MODELO DE PROJETO POR ID
    async getModeloProjeto({ commit }, modeloProjetoId) {
      try {
        const response = await api.get(`/modeloProjeto/${modeloProjetoId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR CHECKLIST DO MODELO DE PEÇA PARA EDIÇÃO
    async getModelosPecasByModeloProjeto({ commit }, modeloProjetoId) {
      try {
        const response = await api.get(
          `/modeloProjeto/${modeloProjetoId}/pecas`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR CHECKLIST DO MODELO DE PEÇA PARA EDIÇÃO
    async getBriefingByModeloProjeto({ commit }, modeloProjetoId) {
      try {
        const response = await api.get(
          `/modeloProjeto/${modeloProjetoId}/briefing`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR ETAPAS DO MODELO DE PEÇA PARA EDIÇÃO
    async getTarefasByModeloProjeto({ commit }, modeloProjetoId) {
      try {
        const response = await api.get(
          `/modeloProjeto/${modeloProjetoId}/tarefas`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // ATUALIZAR UM PROJETO POR ID
    async updateModeloProjeto({ commit }, data) {
      try {
        return await api.put(`/modeloProjeto/${data.modeloProjetoId}`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
