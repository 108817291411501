import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getStates({ commit }) {
      try {
        const response = await api.get('/states')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPaises({ commit }) {
      try {
        const response = await api.get('/paises')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCities({ commit }, stateId) {
      try {
        const response = await api.get(`/cities/${stateId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
