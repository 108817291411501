import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    // CRIAR CATEGORIA DE MODELO DE PEÇAS
    async saveCategoria({ commit }, categoria) {
      try {
        return await api.post('/categorias', categoria)
      } catch (error) {
        throw error
      }
    },

    // CRIAR MODELO DE PEÇAS
    async saveModeloPeca({ commit }, data) {
      try {
        return await api.post('/modeloPeca', data)
      } catch (error) {
        throw error
      }
    },

    // BUSCAR TODAS AS CATEGORIAS
    async getCategorias({ commit }) {
      try {
        const response = await api.get('/categorias')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR TODOS OS MODELOS DE PEÇAS
    async getModelosPecas({ commit }) {
      try {
        const response = await api.get('/modeloPeca')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR OS TIPOS DE ETAPAS DE MODELOS DE PEÇAS
    async getModelosEtapasPecas({ commit }) {
      try {
        const response = await api.get('/modeloEtapaPeca')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR CATEGORIA POR ID
    async getCategoria({ commit }, categoriaId) {
      try {
        const response = await api.get(`/categorias/${categoriaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR MODELO DE PEÇA POR ID
    async getModeloPeca({ commit }, modeloPecaId) {
      try {
        const response = await api.get(`/modeloPeca/${modeloPecaId}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR CHECKLIST DO MODELO DE PEÇA PARA EDIÇÃO
    async getChecklistByModeloPeca({ commit }, modeloPecaId) {
      try {
        const response = await api.get(`/modeloPeca/${modeloPecaId}/checklist`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // BUSCAR ETAPAS DO MODELO DE PEÇA PARA EDIÇÃO
    async getModeloEtapaPecaByModeloPeca({ commit }, modeloPecaId) {
      try {
        const response = await api.get(`/modeloPeca/${modeloPecaId}/etapas`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // ATUALIZAR UMA CATEGORIA DE PEÇA
    async updateCategoria({ commit }, categoria) {
      try {
        return await api.put(`/categorias/${categoria.id}`, categoria)
      } catch (error) {
        throw error
      }
    },

    // ATUALIZAR UM MODELO DE PEÇA
    async updateModeloPeca({ commit }, data) {
      try {
        return await api.put(`/modeloPeca/${data.modeloPecaId}`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
