import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveCargo({ commit }, cargo) {
      try {
        return await api.post('/cargos', cargo)
      } catch (error) {
        throw error
      }
    },

    async getCargos({ commit }) {
      try {
        const response = await api.get('/cargos')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCargo({ commit }, cargoId) {
      try {
        const response = await api.get(`/cargos/${cargoId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateCargo({ commit }, cargo) {
      try {
        return await api.put(`/cargos/${cargo.id}`, cargo)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
