import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveFormaPagamento({ commit }, data) {
      try {
        return await api.post('/forma-pagamento', data)
      } catch (error) {
        throw error
      }
    },

    async getFormasPagamentos({ commit }, data) {
      try {
        let url = `/forma-pagamento`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getFormaPagamento({ commit }, id) {
      try {
        const response = await api.get(`/forma-pagamento/${id}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateFormaPagamento({ commit }, data) {
      try {
        return await api.put(`/forma-pagamento/${data.id}`, data.formaPagamento)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
