import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveSubcategoriaConta({ commit }, data) {
      try {
        return await api.post('/subcategoria-contas', data)
      } catch (error) {
        throw error
      }
    },

    async getSubcategoriasContas({ commit }, data) {
      try {
        let url = `/subcategoria-contas`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSubcategoriaConta({ commit }, subcategoriaContaId) {
      try {
        const response = await api.get(
          `/subcategoria-contas/${subcategoriaContaId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateSubcategoriaConta({ commit }, data) {
      try {
        return await api.put(
          `/subcategoria-contas/${data.subcategoriaContaId}`,
          data.subcategoriaConta,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
